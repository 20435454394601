.bg-color-chose-container {
  * {
    box-sizing: border-box;
  }
  display: flex;
  .use {
    width: 134px;
  }
  .recommend {
    width: calc(100% - 134px);
  }
  .color-block,
  .none {
    width: 22px;
    height: 22px;
    border-radius: 2px 2px 2px 2px;
    &.active {
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
    }
  }
  .none {
    border: 1px solid #999999;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 16px;
      height: 16px;
    }
  }
  .color-block-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .color-block-select {
    width: 84px;
    border-radius: 2px;
    display: flex;
    justify-content: end;
    margin-left: 8px;
    border: 1px solid #dddddd;
    .select {
      width: 22px;
      height: 22px;
      background-color: #eeeeee;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: 5px;
        display: "inline-block";
        width: 0px;
        height: 0px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
      }
    }
  }
}

