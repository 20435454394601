.cover-group {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  p {
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    text-align: center;
    margin-top: 5px;
  }
}

