.componse-modal-wrapper-white {
  width: 484px;

  .componse-wrappper {
    color: #165dff;
  }

  .arco-modal-content {
    padding: 20px 23px;
  }

  .componse-modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tip {
      font-weight: 400;
      font-size: 14px;
      color: #333333;

      span {
        padding: 3px;
      }
    }
  }
}

.componse-modal-wrapper-black {
  .arco-modal-header {
    background: #2c2c2c;
    border-bottom: none;
  }

  .arco-modal-close-icon,
  .arco-modal-title {
    color: white;
  }

  .arco-input-clear-icon,
  .arco-input-group-suffix {
    color: #a5a5a5;
  }

  .arco-input-inner-wrapper-focus {
    border: 1px solid white !important;
    background: #1b1b1c;
  }

  .cancel-btn {
    border: 1px solid #555555;
    background: #1b1b1c;
    color: #f7f7f7;
  }

  .save-btn {
    background: linear-gradient(-270deg, #165dff 0%, #ab50ff 100%);
  }

  .arco-tree-select .arco-tree-select-view {
    border: 1px solid #555555;
    background: #1b1b1c;
    color: #f7f7f7;
  }

  .arco-tree-select-popup {
    background: #323232;
    border: none;
  }

  .arco-tree-node-selected .arco-tree-node-title,
  .arco-tree-node-selected .arco-tree-node-title:hover {
    color: white !important;
  }

  .arco-tree-node-title:hover {
    background: #2c2c2c;
  }

  .arco-tree-node-title {
    color: #a5a5a5;
  }

  .arco-tree-select-focused {
    .arco-tree-select-view {
      border: 1px solid white;
    }
  }

  .arco-input-inner-wrapper:focus {
    background: #1b1b1c;
  }

  .arco-input {
    color: white;
  }

  .arco-input-inner-wrapper {
    border: 1px solid #555555;
  }

  .componse-modal-form-choice-item {
    border: 1px solid #555555;
    background: #1b1b1c;
    color: #a5a5a5;
  }

  .componse-modal-form-choice-item.active {
    border: 1px solid #ffffff;
    background: #1b1b1c;
    color: #ffffff;
  }

  .arco-modal-content {
    background: #1b1b1c;
    color: white;
  }

  .arco-form-label-item {
    &>label {
      color: #ffffff !important;
    }
  }

  .arco-modal-footer {
    background: #1b1b1c;

    .tip {
      color: #898989 !important;

      &>span {
        color: white !important;
      }
    }
  }

  width: 484px;

  .componse-wrappper {
    color: #165dff;
  }

  .arco-modal-content {
    padding: 20px 23px;
  }

  .componse-modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tip {
      font-weight: 400;
      font-size: 14px;
      color: #333333;

      span {
        padding: 3px;
      }
    }
  }
}
