.cover-card-wrapper {
  position: relative;
  margin-left: 16px;
  .delete_wrapper {
    position: absolute;
    display: none;
    right: 12px;
    bottom: 37px;
    width: 16px;
    height: 16px;
    img {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
    }
    cursor: pointer;
  }
  .checkbox-wrapper {
    display: none;
  }
  &.active,
  &:hover {
    img.cover-img {
      border: 2px solid #165dff;
    }
    .delete_wrapper {
      display: block;
    }

    .checkbox-wrapper {
      position: absolute;
      top: -1px;
      left: -1px;
      display: block;
    }
  }
  img.cover-img {
    width: 126px;
    height: 160px;
    border-radius: 8px 8px 8px 8px;
    border: 2px solid transparent;
    object-fit: cover;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    text-align: center;
    width: 130px;
    display: -webkit-box; /* 作为弹性盒子显示 */
    -webkit-box-orient: vertical; /* 垂直布局方向 */
    -webkit-line-clamp: 1; /* 限制显示的行数为1 */
    overflow: hidden; /* 隐藏超出内容 */
    text-overflow: ellipsis; /* 超出部分显示省略号 */
  }
}

