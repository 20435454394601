.voice-config-header {
  height: 34px;
  background: #f9f9f9;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid #eeeeee;
  border-bottom: none;
  .header-title {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
  }
  .config {
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    display: flex;
    align-items: center;
  }
}
.voice-config-contaienr {
  border: 1px solid #eeeeee;
  border-top: none;
  padding: 11px;
}

