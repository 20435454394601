ul.video-config-tab-header {
  display: flex;
  background: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 16px;
  overflow: hidden;
  li {
    cursor: pointer;
    flex: 1;
    height: 82px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    p {
      margin-bottom: 0px;
    }
    &.disabled {
      color: #999;
      cursor: default;
    }
    .img-wrapper {
      img {
        width: 28px;
        height: 28px;
      }
    }

    &.active {
      background: linear-gradient(
        270deg,
        rgba(22, 93, 255, 0.12) 0%,
        rgba(181, 145, 251, 0.12) 100%
      );
    }
  }
}

