.cut-modal {
  .arco-modal-content {
    .time-line-wrapper,
    .frame-timeline-wrapper {
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .time-line-wrapper {
    height: 95px;
  }
  .frame-timeline-wrapper {
    position: relative;
    top: -39px;
  }
}
// #EDEDED

