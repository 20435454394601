.video-close-btn {
  display: none;
  width: 14px;
  height: 14px;
  background: #636363;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  color: white;
  font-size: 7px;
  .close-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.video-group-video-container {
  position: relative;
  &:hover {
    .video-close-btn {
      display: flex;
      cursor: pointer;
    }
  }
  .loading-img {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(51,51,51,0.46);
    border-radius: 8px 8px 8px 8px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .duration-tip {
    height: 14px;
    width: 27px;
    font-weight: 500;
    font-size: 10px;
    color: #ffffff;
    border-radius: 4px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    background: rgba(51, 51, 51, 0.44);
  }
  .getText {
    position: absolute;
    bottom: -2px;
    right: 0px;
    cursor: pointer;
    width: 36px;
    height: 36px;
  }
  .video-item-cover-img {
    width: 125px;
    height: 160px;
    object-fit: cover;
  }
  .video-item-edit {
    position: absolute;
    right: -6px;
    bottom: -8px;
    width: 45px;
    cursor: pointer;
  }
  .video-item-cutted {
    position: absolute;
    right: 8px;
    bottom: 10px;
    width: 15px;
    cursor: pointer;
  }
}

