.video-cut-title-wrapper {
  display: flex;
  padding: 3px 4px;
  background: #f4f4f4;
  border-radius: 4px 4px 4px 4px;
  max-width: 310px;
  overflow-x: auto;
  // overflow-x: scroll;
}

