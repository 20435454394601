.transition-icon-wrapper {
  height: 13px;
  display: flex;
  justify-content: center;
  .transition-icon-container {
    position: relative;
    height: 13px;
    img {
      cursor: pointer;
      width: 38px;
      height: 38px;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }
    .wrapper-number {
      position: absolute;
      top: -27px;
      right: -33px;
      width: 23px;
      height: 23px;
      background: #b591fb;
      border: 2px solid #ffffff;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }
}

