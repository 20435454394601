.flower-img-block {
  width: 54px;
  height: 54px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  img {
    width: 44px;
    height: 23px;
  }
  &.active {
    border-color: #165dff;
  }
}

