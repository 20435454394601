.bg-color-headerleft-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.header-color-block {
  width: 16px;
  height: 16px;
  margin-left: 14px;
  border-radius: 2px;
}

