.cover-card {
  width: 84px;
  height: 84px;
  box-sizing: border-box;
  .img-wrapper {
    width: 83px;
    height: 83px;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    img {
      width: 84px;
      height: 84px;
      object-fit: cover;
    }
  }
  &.active {
    width: 84px;
    height: 84px;
    img {
      width: 84px;
      height: 84px;
    }
  }
}

