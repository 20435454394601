.team_overview {
  // padding: 19px;
  display: flex;
  flex-direction: column;
  // width: 100%;
  max-width: 100%;

  min-height: calc(100% - 19px - 19px);
  background-color: #ffffff;
  padding: 19px;
  .team_overview_header {
    // max-width: 1753px;
    width: calc(100% - 19px);
    min-height: 353px;
    max-height: 35vh;
    background: #ffffff;
    border-radius: 18px 18px 18px 18px;
    border: 1px solid #eeeeee;
    display: flex;
    flex-direction: column;
    .header_contel {
      display: flex;
      justify-content: space-between;
      padding: 24px 40px 30px 28px;
      align-items: center;
      height: 102px;
      .basic-box {
        position: relative;
        font-size: 14px;
        font-weight: bold;
        color: #333;
        font-family: PingFang SC, PingFang SC;
        margin-bottom: 5px;
        .basic-info {
          position: absolute;
          left: 60px;
          top: 0px;
        }
      }
      .contel_L {
        display: flex;
        .logo-img {
          position: relative;
          width: 78px;
          height: 78px;
          .upload-mask {
            cursor: pointer;
            position: absolute;
            width: 78px;
            height: 78px;
            background: rgba(51, 51, 51, 0.8);
            border-radius: 50%;
            left: 0;
            top: 0;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            & > p {
              margin-top: 4px;
              font-family:
                PingFang SC,
                PingFang SC;
              font-weight: 500;
              font-size: 12px;
              line-height: normal;
            }
            display: none;
          }
          &:hover {
            .upload-mask {
              display: flex;
            }
          }
        }
        .contel_Lcomne {
          margin-left: 23px;
          display: flex;
          flex-direction: column;
          h4 {
            font-size: 22px;
            color: #333333;
            line-height: 31px;
            text-align: left;
            display: flex;
            align-items: center;
          }
          p {
            margin-top: 9px;
            font-weight: 400;
            font-size: 16px;
            color: #666666;
            line-height: 22px;
            text-align: left;
          }

          .contel_Lcomne_renzhen {
            width: 65px;
            height: 26px;
            border-radius: 4px 4px 4px 4px;
            font-weight: 400;
            font-size: 14px !important;
            color: #165dff;
            line-height: 22px;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: end;
            padding: 0 6px;
            border: 1px solid #4080ff;
            margin-right: 5px;
          }
        }
      }
      .contel_R {
        display: flex;
        margin-top: 19px;
        .imgbutton {
          width: 99px;
          height: 36px;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #eeeeee;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 22px;
          margin-left: 8px;
        }
        .mov_btn_SH {
          border: 1px solid #ff9900;
          color: #ff9900;
        }
        .mov_btn_Error {
          border: 1px solid #e45a5a;
          color: #e45a5a;
        }
      }
    }
    .contel {
      position: relative;
      flex: 1;
      display: flex;
      margin: 0 28px;
      // padding: 25px 0;
      padding: 30px 0 25px 0;
      border-top: 1px solid #eeeeee;
      .contel-Item {
        width: calc(25% - 20px);
        height: 148px;
        // margin-right: 20px;
        margin: 0 10px;
        background: #f6f9ff;
        border-radius: 8px 8px 8px 8px;
        overflow: hidden;
        .top {
          display: flex;
          justify-content: space-between;
          margin: 17px 34px 0 20px;
          padding-right: 2px;
          align-items: center;
          border-bottom: 1px solid #eeeeee;
          .top_left {
            margin-bottom: 11px;
            h5 {
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              line-height: 22px;
            }
            p {
              margin-top: 9px;
              font-weight: 400;
              font-size: 12px;
              color: #333333;
              line-height: 17px;
              span {
                font-weight: 900;
                font-size: 28px;
                color: #333333;
                line-height: 37px;
                display: inline-block;
                margin-right: 6px;
                span {
                  display: inline-block;
                  font-weight: 400;
                  font-size: 14px;
                  color: #333333;
                  line-height: 17px;
                  margin: 0 0 0 4px;
                }
              }
            }
          }
          img {
            width: 40px;
            height: 40px;
          }
        }
        .bottom {
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          text-align: left;
          margin-left: 20px;
          margin-top: 13px;
        }
        .code_body {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: left;
          img {
            width: 78px;
            height: 78px;
            margin-left: 27px;
            margin-right: 27px;
          }
          .code_contel {
            display: flex;
            flex-direction: column;
            h5 {
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              line-height: 22px;
            }
            p {
              margin-top: 7px;
              font-weight: 500;
              font-size: 12px;
              color: #666666;
              line-height: 17px;
            }
          }
        }
      }
      .oppoation {
        position: absolute;
        position: absolute;
        top: -10px;
        left: 3px;
        width: 233px;
        height: 21px;
        background: #ffffff;
        img {
          width: 169px;
          height: 21px;
        }
      }
    }
  }
  .team_overview_content {
    height: calc(100% - 369px);
    width: 100%;
    background-color: #ffffff;
    // .contel_componts{
    //     margin-top: 20px;
    // }
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
.modal_title_qyrzsurren {
  font-weight: 400;
  font-size: 14px;
  color: #86909c;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-top: 16px;
}
.modal_qirzsurren_contel {
  width: 240px;
  height: 211px;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 2 6px 0px rgba(203, 203, 203, 0.42);
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #eeeeee;
}
.modal_title_qyrzsurren_bold {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.modals_team_overview_qiy {
  width: 100%;
  height: 100%;
  padding: 0 25px;
  .arco-input-inner-wrapper {
    border: 1px solid #f2f3f5;
  }
  .topname {
    margin-top: 24px;
  }
  .input_Social {
    // margin-top: 14px;
    font-weight: 400;
    font-size: 14px;
    color: #1d2129;
    line-height: 22px;
    margin-bottom: 6px;
  }
  .contel_wrapp {
    display: flex;
    width: 524px;
  }
  .arco-upload-list {
    display: none;
  }
  .arco-upload-list-type-text {
    display: none;
  }
  .trigger {
    width: 218px;
    height: 130px;
    border-radius: 2px 2px 2px 2px;
    border: 1px dashed #bfbfbf;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .contell_upload {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .arco-icon-plus {
      width: 11px;
      height: 11px;
      color: #bfbfbf;
    }
    p {
      margin-top: 6px;
      font-weight: 500;
      font-size: 12px;
      color: #bfbfbf;
      line-height: 22px;
    }
  }
  .contell_upload_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 6px;
    p {
      font-weight: 400;
      font-size: 12px;
      color: #aaaaaa;
      line-height: 22px;
      text-align: left;
    }
  }
}
.team-name {
  height: 26px;

  & > div:first-child {
    display: flex;
    align-items: flex-end;
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 500 !important;
    font-size: 22px !important;
    color: #333333;
  }
}

.mov_pro_title_einfo {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4 10px 0px rgba(221, 221, 221, 0.45);
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 8px;

  img {
    width: 14px;
    height: 14px;
    margin-right: 7px;
  }
}

