.card-title {
  .title {
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 280px;
  }
  .desc {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }
}

