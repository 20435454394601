.mingan-header {
  display: flex;
  align-items: center;
  img {
    width: 24px;
    height: 21px;
    margin-right: 12px;
  }
  span {
    font-weight: 500;
    font-size: 16px;
    color: #1D2129;
    font-style: normal;
    text-transform: none;
  }
}
.sensitive-desc {
  font-weight: 400;
  font-size: 14px;
  color: #1D2129;
  line-height: 22px;
  font-style: normal;
  text-transform: none;
  margin-bottom: 9px;
}
.sensitive-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  .all-delete {
    width: 82px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #EEEEEE;
    text-align: center;
    line-height: 32px;
    color: #333333;
    font-size: 14px;
    cursor: pointer;
  }
}
.sensitive-content {
  background: #FFFFFF;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #EEEEEE;
  .arco-collapse-item-header {
    border-bottom: none;
  }
  .arco-collapse-item-content-box {
    padding: 8px 8px 11px 25px;
    background: #fff;
    .sensitive-item {
      width: 100%;
      background: #FAFAFA;
      border-radius: 8px 8px 8px 8px;
      padding: 10px 21px 18px 8px;
      box-sizing: border-box;
      &-content {
        margin-bottom: 6px;
        .sensitive-item-header {
          display: flex;
          justify-content: space-between;
          .con-left {
            display: flex;
            align-items: center;
            .editInput {
              height: 28px;
              margin-bottom: 2px;
              background: #F1F0F0;
              border-radius: 32px 32px 32px 32px;
              border: none;
              width: 269px;
            }
            .line {
              max-width: 200px;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              height: 28px;
              background: #FBE5E4;
              border-radius: 32px 32px 32px 32px;
              text-align: left;
              padding: 0 11px;
              box-sizing: border-box;
              font-weight: 400;
              font-size: 14px;
              color: #F98282;
              line-height: 28px;
              font-style: normal;
              text-transform: none;
            }
            .edit-replace {
              max-width: 200px;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              height: 28px;
              background: #F0F5FF;
              border-radius: 32px 32px 32px 32px;
              text-align: left;
              padding: 0 11px;
              box-sizing: border-box;
              font-weight: 400;
              font-size: 14px;
              color: #165DFF;
              line-height: 28px;
              font-style: normal;
              text-transform: none;
            }
          }
          .con-btn {
            img {
              width: 22px;
              height: 22px;
              cursor: pointer;
            }
          }
        }
        .sensitive-item-info {
          font-weight: 400;
          font-size: 12px;
          color: #888888;
          font-style: normal;
          display: flex;
          align-items: center;
          padding-left: 41px;
          width: 100%;
          box-sizing: border-box;
          img {
            width: 22px;
            height: 22px;
            cursor: pointer;
          }
        }
      }
    }
  }
  &-nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .title {
      width: 130px;
      display: flex;
      align-items: center;
    }
  }
  .btn-group {
    max-width: 304px;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .btns {
      font-size: 12px;
      font-weight: 500;
      height: 26px;
      min-width: 50px;
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #EEEEEE;
      padding: 0 8px;
      line-height: 26px;
      text-align: center;
      color: #333333;
      cursor: pointer;
      margin-right: 6px;
    }
    .btns:nth-last-child {
      margin-right: 0;
    }
    .btns.active {
      color: #165DFF;
    }
  }
  .btn-group::-webkit-scrollbar {
    height: 3px;
  }
}

