.close,
.open {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 5px;
  position: relative;
  cursor: pointer;
}
.close {
  border-color: transparent transparent transparent black;
}
.open {
  border-color: black transparent transparent transparent;
  top: 2px;
  left: -7px;
}

