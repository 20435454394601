.header {
  background-color: white;
  padding: 22px 14px 17px;
  border-bottom: 1px solid #eaeaea;
  position: relative;
  display: flex;
  justify-content: space-between;
  > img {
    width: 36px;
    height: 33px;
    cursor: pointer;
  }
  .edit-wrapper-container {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-left: 6px;
    }
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .cut-detail-action {
    display: flex;
    align-items: center;
    .time {
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      margin-right: 26px;
    }
    .button {
      background: linear-gradient(
        135deg,
        rgba(22, 93, 255, 0.18) 0%,
        rgba(181, 145, 251, 0.18) 100%
      );
      border-radius: 2px 2px 2px 2px;
      color: black;
    }
  }
}

