.custom-music-group {
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: baseline;
  padding: 8px;
  padding-right: 0;
  .chose-wrapper {
    font-weight: 400;
    font-size: 12px;
    color: #666666;
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .delete-btn {
    position: absolute;
    bottom: 6px;
    right: 10px;
    > img {
      width: 9px;
    }
    cursor: pointer;
    z-index: 100;
  }
  .music-card {
    width: 126px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fafafa;
    border-radius: 8px 8px 8px 8px;
    img {
      width: 100%;
      height: 20px;
    }
  }
  .custom-music-card {
    p {
      text-align: center;
      font-weight: 400;
      font-size: 12px;
      color: #333333;
    }
  }
}

