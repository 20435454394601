.music-card {
  height: 78px;
  display: flex;
  position: relative;
  box-sizing: border-box;
  border: 1px solid #d6d6d6;
  position: relative;
  &.active {
    border-width: 2px;
    border-color: #165dff;
    .checkout-wrapper {
      display: inline-block;
    }
  }
  .checkout-wrapper {
    position: absolute;
    top: -1px;
    left: -2px;
    display: none;
  }

  &:hover {
    .checkout-wrapper {
      display: inline-block;
    }
  }
  .img-wrapper {
    position: relative;
    cursor: pointer;
    .preview-img {
      width: 67px;
      height: 67px;
      margin-left: 3px;
      margin-top: 3px;
    }
    .play-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      transform: translate(-50%, -50%);
    }
  }

  .music-msg-wrapper {
    padding: 6px;
    margin-left: 6px;
    width: calc(100% - 87px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    .name {
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      white-space: nowrap;
      overflow: hidden;
      width: 92px;
      text-overflow: ellipsis;
      display: -webkit-box; /* 必须使用 -webkit-box */
      -webkit-line-clamp: 1; /* 限制显示两行 */
      -webkit-box-orient: vertical; /* 必须指定为 vertical */
    }

    .singer {
      font-weight: 400;
      font-size: 12px;
      color: #999999;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box; /* 必须使用 -webkit-box */
      -webkit-line-clamp: 1; /* 限制显示两行 */
      -webkit-box-orient: vertical; /* 必须指定为 vertical */
    }
    .bottom {
      bottom: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .time {
        font-weight: 500;
        font-size: 10px;
        color: #999999;
      }
      img {
        width: 10px;
        height: 10px;
        cursor: pointer;
      }
    }
  }
}

