.bg-cover-config-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.choice-wrapper {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  margin-top: 6px;
}

