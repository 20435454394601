.transition-header {
  display: flex;
  justify-content: space-between;
  .transition-timer {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    .arco-input-group-wrapper.arco-input-group-wrapper-mini {
      border: 1px solid #dddddd !important;
      border-radius: 4px;
      padding: 2px 2px;
    }
    .arco-input-number-step-button {
      background-color: white;
      border: none;
    }
    .arco-input-inner-wrapper-mini {
      border: none;
    }
  }
}

