.empty-div1 {
  //border: 1px solid #eeeeee; /* Red border like in the image */
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 62px;
  height: 22px;
}

.custom-line {
  position: absolute;
  top: 45%;
  left: -8%;
  width: 130%;
  height: 1px;
  background-color: #eeeeee;
  transform: rotate(-16deg);
}

