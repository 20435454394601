.bg-music-container {
  flex: 1;
  padding-left: 9px;
  padding-top: 6px;
  padding-right: 10px;
  box-sizing: border-box;
  height: 410px;
  overflow-y: auto;
  .bg-music-search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .empyt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    /* align-content: center; */
    height: 80%;
    align-items: center;
    img {
      width: 90px;
    }
    p {
      font-weight: 400;
      font-size: 12px;
      color: #666666;
    }
  }
  .music-card-wrapper {
    width: 100%;
    height: calc(100% - 20px);
    .music-card-container {
      width: 100%;
      height: 100%;
      .arco-spin-children {
        width: 100%;
        max-height: 100%;
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
      }
    }
  }
  .chose-wrapper {
    font-weight: 400;
    font-size: 12px;
    color: #666666;
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

