.transition-group {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-between;
  .transition-card-wrapper {
    > p {
      text-align: left;
      font-size: 14px;
      margin-top: 6px;
    }
  }
}

