.open-icon-wrapper {
  display: flex;
  width: fit-content;
  .span {
    font-weight: 400;
    font-size: 12px;
    color: #333333;
  }
  .close,
  .open {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 5px;
    position: relative;
    cursor: pointer;
    margin-left: 4px;
  }
  .open {
    border-color: transparent transparent black transparent;
    top: 0px;
    left: 2px;
  }
  .close {
    border-color: black transparent transparent transparent;
    left: 2px;
    top: 7px;
  }
}

