.color-line-block {
  width: 18px;
  height: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  img {
    width: 10px;
    height: 10px;
  }

  .line {
    width: 18px;
    height: 3px;
    box-sizing: border-box;
    margin-top: 1px;
    border: 1px solid black;
    position: relative;
    top: -5px;
    right: 3px;
  }
}

