.wrapel_contel {
  width: 264px;
  height: 528px;
  padding-bottom: 32px;
  // height: auto;
  background: #ffffff;
  box-shadow: 0px 2 6px 0px rgba(203, 203, 203, 0.42);
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #eeeeee;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  .Input_childn {
    width: 233px;
    height: 38px;
    padding: 0 15px;
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    margin-top: 17px;
  }
  .companydiv {
    width: 221px;
    height: 38px;
    padding-left: 34px;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 38px;
    text-align: left;
    // margin-top: 17px;
  }
  .btns_contel{
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: end;
    background-color: #FFFFFF;
    .arco-btn{
      margin-right: 10px;
      width: 60px;
      height: 32px;
      border-radius: 2px;
      background: #165DFF;
      font-size: 14px;
      text-align: center;
      margin-bottom: 11px;
    }
    .dis_err{
      color: #4E5969;
      background: #F2F3F5;
    }
  }
  div {
    // border: none !important;
    // transition:none !important;
  }
  .arco-collapse-item-content {
    background-color: #ffffff;
    transition: height 0.2s cubic-bezier(0.34, 0.69, 0.1, 1) !important;
  }
  .arco-collapse-item {
    border: none;
    font-weight: 400;
    font-size: 14px;
    color: #417aff;
    line-height: 22px;
  }
  .arco-collapse-borderless {
    // width: 228px;
    // height: 31px;
    // padding-left: 50px;
    // width: calc(100% - 50px);
  }
  .arco-collapse-item-active > .arco-collapse-item-header {
    background: #f1f3fd;
    border-radius: 0px 0px 0px 0px;
    color: #417aff;
    .arco-icon-caret-right {
      color: #417aff;
    }
  }
  .arco-collapse-item-active > .arco-collapse-item-header {
    border: none;
    transition: none;
  }
  .arco-collapse-item-header {
    transition: none;
  }
}
.wrapel_contel::-webkit-scrollbar {
  display: none;
}
// .arco-popover-arrow{
//  display: none;
// }

