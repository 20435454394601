.bg-music-config-nav {
  width: 73px;
  background: #f4f4f4;
  padding-left: 9px;
  box-sizing: border-box;
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 13px;
  li {
    font-weight: 500;
    cursor: pointer;
    font-size: 12px;
    padding-top: 9px;
    color: #333333;
    &.active {
      background: linear-gradient(180deg, #165dff 0%, #be7bff 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

