.chose-detail-bar-wrapper {
  display: flex;
  align-items: center;
  > span {
    font-weight: 400;
    font-size: 12px;
    color: #999999;
  }
  > .chose-detail-content-wrapper {
    .title-wrapper > span {
      font-weight: 400;
      font-size: 12px;
      color: #999999;
      margin-top: 6px;
    }
  }
}
.chose-detail-popover-wrapper {
  border: 1px solid #dddddd;
  .arco-popover-inner-content {
    padding: 14px;
    background-color: white;
    width: 350px;
    box-sizing: border-box;
  }
  .chose-detail-content-wrapper {
    .title-wrapper {
      width: 100%;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 12px;
      color: #333333;
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
    }
    width: 330px;
  }
}

