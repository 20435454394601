.mix-cut-video-wrapper {
  margin-top: 14px;
  box-sizing: border-box;

  ul {
    display: flex;
    padding: 18px 19px;
    justify-content: space-between;
    border-radius: 2px 2px 2px 2px;
    background: #f9f9f9;
    width: 100%;
    box-sizing: border-box;
    li {
      width: 50%;
    }
    .title {
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      span {
        font-size: 12px;
        font-weight: 400;
        display: inline-block;
        transform: scale(0.7);
        position: relative;
        top: 1px;
      }
    }
  }
}

