.timberSelector {
  display: flex;
  .timber-selector-block {
    padding: 8px 14px;
    background: #f9f9f9;
    border-radius: 8px 8px 8px 8px;
    margin-right: 12px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    &.active {
      background: linear-gradient(
        270deg,
        rgba(22, 93, 255, 0.12) 0%,
        rgba(181, 145, 251, 0.12) 100%
      );
    }
  }
}

