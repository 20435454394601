.add-button-wrapper {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editor-wrapper-title {
  .tiptap.ProseMirror {
    height: 106px;
    overflow-y: auto;
  }

  .voice-container-bar-title {
    position: absolute;
    bottom: 4px;

    >.insert-opeator {
      cursor: pointer;
      display: flex;

      >img {
        width: 73px;
        height: 22px;
      }

      .start-time {
        height: 22px;
        padding: 0px 6px;
        background-color: #edf4ff;
        font-weight: 400;
        font-size: 12px;
        color: #165dff;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 10px;
          height: 10px;
          margin-right: 6px;
        }
      }
    }
  }
}

.title-group-current-active {
  .video-config-card-header {
    border-color: #165dff;
  }

  .video-config-card-container {
    border-color: #165dff;
  }
}

.global-title-container-wrapper {

  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
  }
}
