.vl-audio-player {
  padding: 5px 15px;
  background: rgba(255, 255, 255, 0.88);
  .title {
    display: flex;
    justify-content: space-between;
    span {
      font-weight: 500;
      font-size: 12px;
      color: #333333;
    }
  }
  .audio-cotainer {
    background: #f2f3f5;
    border-radius: 4px 4px 4px 4px;

    > * {
      display: flex;
      align-items: center;
    }
    display: flex;
    align-items: center;
    .time-count {
      font-weight: 400;
      font-size: 10px;
      color: #333333;
    }
    .play-process {
      flex: 1;
      padding-left: 8px;
      .arco-slider-button:after {
        width: 14px;
        height: 14px;
        background: #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
        border-radius: 8px 8px 8px 8px;
        border: none;
      }
      .arco-slider-road::before {
        height: 4px;
      }
      .arco-slider-bar {
        height: 4px;
      }
    }

    .volumn {
      margin-left: 10px;
      .arco-slider.arco-slider-vertical {
        width: 10px !important;
        min-width: 10px !important;
      }
    }
  }
}

