.transition-card-wrapper {
  position: relative;
}

.transition-card {
  width: 208px;
  height: 108px;
  box-sizing: border-box;
  border-radius: 8px 8px 8px 8px;
  overflow: hidden;

  .checkbox-wrapper {
    position: absolute;
    left: 9px;
    top: 6px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

