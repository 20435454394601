.title-group-tag-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .add-btn {
    font-size: 12px;
    color: #333333;
  }
  .button-group {
    position: absolute;
    right: 80px;
    display: flex;
    .button-group-block-wrapper {
      width: 28px;
      height: 28px;
      background: #f4f4f4;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #666666;
      &.active {
        color: #165dff;
        cursor: pointer;
      }
    }
  }
}

