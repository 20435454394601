.custom-cover-container {
  display: flex;
  flex-wrap: wrap;
  .customCover-wrapper {
    position: relative;
    margin-left: 4px;
    margin-bottom: 8px;
    &:hover {
      .delete-icon {
        display: flex;
      }
    }
    .delete-icon {
      display: none;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 100;
    }
  }
}

