.border-radius-search {
  .arco-input-inner-wrapper {
    border-radius: 16px;
    border: 1px solid #ccc;
  }
}
.bg-search {
  margin-right: 10px;
}
.bg-search .border-radius-search .arco-input-inner-wrapper {
  border-color: #DDDDDD;
  height: 24px;
}
.bg-search .border-radius-search .arco-input-inner-wrapper input::-webkit-input-placeholder { /* Chrome, Opera, Safari */
  color: #999999;
  font-size: 12px;
}
.bg-search .border-radius-search .arco-input-inner-wrapper input {
  color: #999999;
}
.small-search-wrapper {
  transition: all 0.3s;
}
.small-search-wrapper .arco-input-inner-wrapper-focus {
  border-color: #DDDDDD !important;
}
.small-search-wrapper .arco-input-inner-wrapper:hover {
  background: #ffffff;
}
.search-circle {
  height: 24px;
  background: #FFFFFF;
  border-radius: 50px 50px 50px 50px;
  border: 1px solid #DDDDDD;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0 10px;
  font-size: 12px;
  color: #999999;
  .search-icon {
    margin-right: 6px;
  }
}

