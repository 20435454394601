.video-group-item-wrapper {
  border: 1px solid #eeeeee;
  border-radius: 8px 8px 8px 8px;
  background: white;
  &.error.active {
    border-color: #f98282 !important;
  }
  &.active {
    border-color: #165dff;
  }

  .hover-display-img {
    display: none;
    cursor: pointer;
  }

  &:hover {
    .hover-display-img {
      display: inline-block;
    }
  }
  .video-group-item-header {
    background: #f7faff;
    border-radius: 8px 8px 0px 0px;
    height: 44px;
    // padding: 11px 24px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name-wrapper,
    .opeator {
      display: flex;
      align-items: center;
      .name {
        font-weight: 500;
        font-size: 16px;
        color: #333333;
      }
      .time-wrapper {
        font-weight: 400;
        font-size: 12px;
        color: #666666;
      }
    }
    .duration-range {
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      span {
        font-size: 10px;
      }
    }
  }
  .video-group-item-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    .video-group-material-wrapper {
      flex: 1;
      display: flex;
      overflow-x: auto;
    }
    &.hidden {
      max-height: 0;
      overflow: hidden;
      padding: 0;
    }
    display: flex;
    padding: 14px 22px;
    border-bottom: 1px solid #eeeeee;
    .mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #bfbfbf;
      button.arco-btn {
        background: white;
        border: none;
        border-radius: 4px;
        &:hover {
          opacity: 0.7;
          background: white !important;
        }
      }
    }

    .cut-material-upload:hover .mask {
      display: flex;
    }
    .video-box {
      width: 126px;
      height: 161px;
      // background-color: red;
      margin-left: 8px;
    }
  }
  .video-group-item-footer {
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;

    .line-title {
      display: flex;
      width: calc(100% - 300px);
      flex-wrap: nowrap;
      overflow: hidden; /* 让超出部分内容在父容器内滚动 */

      .item {
        max-width: calc(50% - 28px);
        background: #fafafa;
        border-radius: 4px;
        border: 1px solid #eeeeee;
        display: inline-flex;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        color: #333333;
        line-height: 22px;
        padding: 2px 8px;
        padding-right: 5px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-shrink: 0;
        min-width: 0;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        .item-container {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:hover {
          opacity: 0.7;
        }
      }
    }

    .opeator-bar {
      width: 242px;
    }
  }
}
.video-popover-error {
  border: 1px solid #f98282;
  padding-top: 2px;
  padding-right: 8px;
  padding-bottom: 2px;
  padding-left: 2px;
  background: #fafafa;
  box-shadow: 0px 2px 4px 0px #e2e2e2;
  border-radius: 8px;
  p.content {
    display: flex;
    align-items: center;
    .error-message {
      width: 162px;
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      padding-top: 2px;
      margin-left: 4px;
    }
  }
  .arco-popover-arrow {
    border-color: #f98282;
  }
}
.text-box {
  padding: 13px 16px;
  .text-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;
    .title {
      font-weight: 500;
      font-size: 14px;
      color: #333333;
      text-align: left;
    }
    .btns {
      font-size: 12px;
    }
  }
  .text-content {
    height: 163px;
    overflow-y: auto;
    font-size: 12px;
    text-align: left;
    background: #F5F8FF;
    border-radius: 6px 6px 6px 6px;
    padding: 10px 9px 13px 12px;
  }
}

