.line-font-config-line {
  display: flex;
  align-items: center;
  .line-font-config-box {
    width: 50%;
    display: flex;
    align-items: center;
    .font-config-number-input-wrapper {
      .arco-input-inner-wrapper {
        border: none;
        background: #f9f9f9;
      }
    }
  }
  .font-setting-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .border {
    .arco-select-view {
      border: none;
    }
  }

  .title {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
  .font-setting-config-container {
    margin-left: 16px;
    display: flex;
    align-items: center;
    background: #f9f9f9;
    > img {
      width: 18px;
      margin-left: 15px;
    }
    .font-select {
      position: relative;
      left: 2px;
      top: 3px;
      .slideDynamicOrigin-enter-done {
        > .arco-select-popup {
          width: 42px !important;
          overflow: visible !important;
        }
      }
      .arco-select-arrow-icon {
        display: none;
      }

      .arco-select-view {
        padding: 0;
      }
    }
  }
}
.select-bg {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  .title {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    min-width: 73px;
  }
}
.flower-font-choose {
  display: flex;
  margin-top: 24px;
  .title {
    position: relative;
    top: 5px;
    width: 73px;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
}

