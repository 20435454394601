.team_overview_content_tabs{
    width: 162px;
    height: 36px;
    background: #F4F4F4;
    border-radius: 4px 4px 4px 4px;
    font-size: 12px;
    color: #333333;
    line-height: 17px;
    text-align: center;
    .arco-radio-button{
        width: 72px;
        font-size: 12px;
        height: 28px;
        border-radius: 4px 4px 4px 4px; 
        margin: 2px 3px;
    }
    .arco-radio-button.arco-radio-checked{
        font-weight: 600;
        color: #165DFF;
        background-color: #FFFFFF;
      
    }
    .arco-radio-button:hover {
        font-weight: 600;
        color: #165DFF;
        background-color: #FFFFFF;
    }
    .arco-radio-button-inner{
        padding: 0;
    }
}

.contel_componts{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: #FFFFFF;
    z-index: 100;
    padding: 35px 0 15px 0 ;
    height: calc( 100% - 369px);
    width: calc( 100%  - 19px);
    background-color: #FFFFFF;
    .contel_R{
        display: flex ;
        .plearthPhone{
            margin-right: 19px;
            width: 276px;
            height: 38px;
            background: #FFFFFF;
            border-radius: 2px 2px 2px 2px;
        }
        .optab_select{
            width: 200px;
            margin-right: 19px;
            .arco-select-view{
                width: 200px;
                height: 38px !important;
                background: #FFFFFF;
                border-radius: 2px 2px 2px 2px;
                border: 1px solid #DDDDDD;
            }
        }
        .select_data{
            width: 240px;
            height: 38px;
            background: #FFFFFF;
            border-radius: 2px 2px 2px 2px;
            border: 1px solid #DDDDDD;
        }
    }
    .Filtememberscontexttabs {
        width: 376px;
        .arco-tabs-header-nav-line .arco-tabs-header-title-text::before{
          left: 0 !important;
          right: 0 !important;
          // height: 38px !important;
        }
        .arco-tabs-header {
          height: 38px;
          background: #F4F4F4;
          border-radius: 4px 4px 4px 4px;
        }
        .arco-tabs-header-title {
          width: 82px;
          height: 28px;
          background: #F4F4F4;
          border-radius: 4px 4px 4px 4px;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 22px;
          text-align: center;
          margin: 5px !important;
        }
    }
    .arco-tabs-header-title {
        width: 82px;
        height: 28px;
        background: #F4F4F4;
        border-radius: 4px 4px 4px 4px;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 22px;
        text-align: center;
        margin: 5px !important;
      }
      .arco-tabs-header-title-text {
        width: 100%;
        display: inline-block;
    }
    .arco-tabs-header-title-active {
        width: 82px;
        height: 28px;
        background: #FFFFFF !important;
        border-radius: 4px 4px 4px 4px;
        font-weight: bold;
        font-size: 14px;
        color: #165DFF !important;
        line-height: 22px;
        text-align: center;
        margin: 5px !important;
        .arco-tabs-header-title-text {
            width: 100%;
            display: inline-block;
        }
    }
    .arco-tabs-header-nav::before {
        background-color: none;
        width: 0;
        height: 0;
        border: none;
    }
    .arco-tabs-header-ink{
        width: 0;
        height: 0;
    }
}
.selccompont_tabdata{
    width: calc(100% - 19px);
    margin-top: 20px;
    position: relative;
    margin-bottom: 32px;
    flex: 1;
    display: flex;
    flex-direction: column;
    .arco-table{
        flex: 1;
        display: flex;
        flex-direction: column;
        .arco-spin{
            display: flex;
            flex-direction: column;
            flex: 1;
            .arco-spin-children{
                display: flex;
                flex-direction: column;
                flex: 1;
                .arco-table-container{
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    .arco-table-content-scroll{
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        .arco-table-content-inner{
                            display: flex;
                            flex-direction: column;
                            // flex: 1;
                        }
                    }
                }
            }
        }

    }
    table{
        flex: 1;
        border: none;
        thead{
            th{
                height: 44px;
                background: #F8FAFD;
                border-radius: 0px 0px 0px 0px;
                // font-size: 16px;
                color: #333333;
                line-height: 22px;
                font-weight: bold;
                border-bottom: none !important;
            }
        }
        tbody{
            height: calc(100% - 58px - 44px);
            overflow: scroll;
            tr{
                height: 59px;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
                line-height: 22px;
                .arco-table-td{
                    border-bottom: 1px solid #EEEEEE;
                }
            }
        }
    }
    .tab-pages{
        // width: calc(100% - 107px - 46px);
        width: 100%;
        position:absolute;
        height: 32px;
        bottom: -40px;
        // width:calc(100% - 15px);
        // position: absolute;
        // bottom: 15px;
        // display: flex;
        background-color: #FFFFFF;

        .arco-pagination-total-text{
            flex: 1;
        }
        .arco-pagination-jumper{
            display: none;
        }
    }
    .opdatatitle{
        text-align: center;
        font-size: 14px;
        color: #FFFFFF;
        padding: 0 12px;
        height: 26px;
        display: inline-block;
        line-height: 26px;
    }
    .opred{
        background: #F18888;
        border-radius: 4px 4px 4px 4px;
    }
    .opgren{
        background: #5EC279;
        border-radius: 4px 4px 4px 4px;
    }
}
