.timbre-card {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 135px;
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #e4e4e4;
  padding: 10px 14px;
  min-height: 60px;
  cursor: pointer;
  &.active {
    border-color: #165dff;
    background: #f3f8ff;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .timbre-msg {
    margin-left: 8px;
    .title {
      font-weight: 500;
      font-size: 14px;
      color: #333333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
      width: 60px;
    }
    .sub-title {
      font-weight: 400;
      font-size: 12px;
      color: #979797;
      width: 48px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

