.video-config-simple-title-wrapper {
  .tiptap.ProseMirror {
    height: 106px;
    overflow-y: auto;
  }

  &.title-item-active {
    .video-config-card-header {
      border-color: #165dff;
    }

    .video-config-card-container {
      border-color: #165dff;
    }
  }

  .arco-textarea {
    background: white;

    &:focus {
      border-color: transparent;
    }

    &:hover {
      background: white;
    }
  }

  .delete-title-item {
    cursor: pointer;
  }

  .video-config-card-container-item {
    position: relative;

    .voice-container-bar-title {
      position: absolute;
      bottom: 6px;

      .insert-opeator {
        cursor: pointer;
        display: flex;

        .start-time {
          height: 22px;
          padding: 0px 6px;
          background-color: #edf4ff;
          font-weight: 400;
          font-size: 12px;
          color: #165dff;
          display: flex;
          align-items: center;

          >img {
            width: 10px;
            height: 10px;
            margin-right: 6px;
          }

          justify-content: center;
        }
      }
    }
  }


}
