.timbre-slider-wrapper {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  .title {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    margin-right: 18px;
    text-wrap: nowrap;
  }
  .value {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    margin-left: 8px;
  }

  .arco-slider-wrapper {
    position: relative;
    z-index: 1;
    &::after,
    &::before {
      content: "";
      display: inline-block;
      position: relative;
      width: 1px;
      height: 10px;
      background: #165dff;
      top: 10px;
      transform: translate(-50%, -100%);
    }
    &::after {
      background: #dddddd;
    }
  }
  .arco-slider.full {
    z-index: 1;
    position: relative;
    .arco-slider-wrapper {
      &::after {
        content: "";
        display: inline-block;
        position: relative;
        background: #165dff;
        top: 10px;
        transform: translate(-50%, -100%);
      }
    }
  }
  .arco-slider-tick {
    top: 12px;
    z-index: 1;
    display: none;
  }

  .arco-slider-button {
    z-index: 100;
  }

  .arco-slider-button::after {
    width: 8px;
    height: 19px;
    background: #ffffff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #e4e4e4;
    top: -5px;
    z-index: 100;
  }
}

