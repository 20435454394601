.componse-modal-form-choice-item {
  min-width: 104px;
  height: 32px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #dddddd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
  span {
    white-space: nowrap;
    font-size: 12px;
    color: #666666;
  }
  &.active {
    background: #f3f8ff;
    border: 1px solid #165dff;
    color: #165dff;
  }
}

