.custom-timbre-selector-wrapper {
  width: calc(100% + 22px);
  margin-left: -11px;
  margin-top: 20px;
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  li.timbre-card-wrapper {
    margin-bottom: 16px;
    margin-left: 16px;
  }
  .oepn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
  }
}

