.flower-font-group-wrapper {
  display: flex;
  flex-wrap: wrap;
  .flower-font-block-wrapper {
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

.open-wrapper {
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  font-size: 12px;
  color: #333333;
  width: fit-content;
  .open-icon {
    position: relative;
    left: -11px;
    top: 4px;
  }
}

