.choice-block {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  > img {
    width: 16px;
    height: 16px;
  }
}

