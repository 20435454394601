.self-upload-card {
  width: 126px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  cursor: pointer;
  border-radius: 8px 8px 8px 8px;
  p {
    font-weight: 400;
    font-size: 12px;
    color: #666666;
  }
}

