.title-group-tag-item {
  font-weight: 400;
  font-size: 12px;
  color: #333333;
  position: relative;
  min-width: 60px;
  background-color: red;
  word-wrap: no-wrap;
  text-align: center;

  &.active {
    color: #165dff;
    background: white;
  }
  padding: 3px 16px;
  background: #f4f4f4;
  cursor: pointer;

  .icon-close {
    display: none;
    position: absolute;
    top: 6px;
    transform: scale(0.7);
  }

  &:hover {
    .icon-close {
      display: inline-block;
    }
  }
}

