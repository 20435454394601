.video-wrapper {
  width: 360px;
  margin-top: 7px;
  position: relative;
  p {
    margin-bottom: 0 !important;
  }
  .loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    flex-direction: column;
    p {
      color: white;
      margin-top: 20px;
    }
  }

  .video-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
}
.preview-select-wrapper {
  margin-top: 12px;
  display: flex;
  align-items: center;
  span {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
}

