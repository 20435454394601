.mix-cut-detail-container {
  display: flex;
  max-height: calc(100vh - 84px);
  overflow-y: scroll;
}
.loading-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 155px;
  padding: 42px 0 20px 0;
  box-sizing: border-box;
  .text {
    margin-top: 29px;
  }
}

