.global-caption {

  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
  }

  .caption-header {
    display: flex;
    align-items: center;

    img {
      margin-left: 6px;
      width: 16px;
      cursor: pointer;
    }
  }
}

.editor-wrapper {
  .arco-textarea {
    background: white;

    & {
      border: none;
    }

    &:focus {
      border: none;
    }

    &:hover {
      background: white;
    }
  }

  .tiptap.ProseMirror {
    height: 106px;
    overflow-y: auto;
  }

  .ai-line {
    width: 73px;
    height: 22px;
    cursor: pointer;
  }

  .voice-container-bar {
    display: flex;
    padding-top: 22px;
    padding-right: 9px;
    padding-bottom: 9px;
    // border: 1px solid #eeeeee;
    border-top: none;

    .clear-box-wrapper {
      width: 76px;
      height: 22px;
      background: #edf4ff;
      border-radius: 4px 4px 4px 4px;
      font-weight: 400;
      font-size: 12px;
      color: #165dff;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 11px;
        height: 11px;
        margin-right: 4px;
      }
    }

    .preview-time {
      position: absolute;
      right: 10px;
      font-weight: 400;
      font-size: 12px;
      color: #999999;
      display: flex;
      justify-content: center;
      align-items: center;

      &>:last-child {
        color: #666666;
      }

      .preview-info {
        background: #EDF4FF;
        border-radius: 4px 4px 4px 4px;
        color: #165DFF;
        padding: 2px 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 12px;
      }
    }

    .insert-opeator {
      display: flex;

      .tags {
        width: 76px;
        height: 22px;
        background: #edf4ff;
        border-radius: 4px 4px 4px 4px;
        font-weight: 400;
        font-size: 12px;
        color: #165dff;
        display: flex;
        justify-content: center;
        align-items: center;

        &.insert-stop {
          img {
            width: 11px;
            height: 11px;
            margin-right: 4px;
          }
        }
      }

      .ai-line {
        width: 73px;
        height: 22px;
        cursor: pointer;
      }
    }

    .clear-wrapper {
      font-weight: 400;
      font-size: 12px;
      color: #333333;
    }
  }
}
