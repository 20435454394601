.mix-cut-detail-videogroup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .video-group-title {
    font-weight: 500;
    font-size: 18px;
    color: #333333;
  }
  .video-group-introduce {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    margin-top: 5px;
  }
}
.bottom-wrapper {
  display: flex;
  justify-content: center;
}
.add-button {
  width: 112px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  &:hover {
    opacity: 0.7;
  }
  background: linear-gradient(
    90deg,
    rgba(22, 93, 255, 0.15) 0%,
    rgba(198, 150, 250, 0.15) 100%
  );
}
.modal-script-export {
  img {
    width: 20px;
    margin-right: 10px;
  }
  .modal-script-header {
    display: flex;
    align-items: center;
  }
  .modal-script-content {
    padding: 25px 61px;
    font-weight: 500;
    font-size: 16px;
    color: #1d2129;
  }
}

